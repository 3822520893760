.main-app {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

.main-app-right-part, .customer-panel-content {
    width: calc(100% - var(--menu-size));
}

.main-menu {
    height: 100%;
    position: relative;
}

.menu-container {
    padding: 15px;
}

.main-menu-container, .customer-menu {
    height: 100%;
    position: relative;
    width: var(--menu-size);
    border-right: 1px solid var(--black-5);
}

.app-logo {
    width: var(--menu-size);
    height: 60px;
    padding: 8px 10px;
    border-bottom: 1px solid var(--black-5);
}

.app-logo img, .app-logo a {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.main-navbar {
    display: flex;
    border-bottom: 1px solid var(--black-5);
    height: 60px;
}

.app-logo, .menu-item {
    display: flex;
    align-items: center;
}

.menu-item {
    height: 55px;
    padding-left: 60px;
    text-decoration: none;
    color: var(--black-100);
    font-weight: 600;
    transition: all .1s;
    position: relative;
    z-index: 0;
}

.menu-item:before {
    content: "";
    position: absolute;
    left: 19px;
    top: 17px;
    width: 22px;
    height: 22px;
    background: url('../icons/users-blue.svg') no-repeat;
    background-size: contain;
}

.menu-item.active:before {
    background: url('../icons/users-blue.svg') no-repeat;
    background-size: contain;
}

.menu-item.trainings:before {
    background: url('../icons/swimmer.svg') no-repeat;
    background-size: contain;
}

.menu-item.trainings.active:before {
    background: url('../icons/swimmer-blue.svg') no-repeat;
    background-size: contain;
    top: 29px;
}

.menu-item.subscriptions:before {
    background: url('../icons/ticket-blue.svg') no-repeat;
    background-size: contain;
    left: 21px;
}

.menu-item.subscriptions.active:before {
    background: url('../icons/ticket-blue.svg') no-repeat;
    background-size: contain;
    left: 23.5px;
}

.menu-item.pools:before {
    background: url('../icons/home-location-alt.svg') no-repeat;
    background-size: contain;
}

.menu-item.pools.active:before {
    background: url('../icons/home-location-alt-blue.svg') no-repeat;
    background-size: contain;
}

.menu-link:hover {
    text-decoration: none;
}

.new-button {
    position: absolute;
    right: 18px;
    top: 17px;
    font-size: 28px;
    font-weight: 200;
    color: var(--black-70);
    transition: all .5s;
    z-index: 2;
}

.new-button:hover {
    color: var(--white-100);
}

.new-button:before {
    content: '';
    position: absolute;
    top: 9px;
    right: -5px;
    width: 26px;
    height: 26px;
    border: 1px solid var(--black-15);
    border-radius: 9px;
    z-index: -1;
    transition: all .5s;
}

.new-button:hover:before {
    background: var(--blue-100);
    border: 1px solid var(--blue-100);
}

.searchbar-container {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.searchbar-item input {
    height: 34px;
    width: 100%;
    border: 1px solid var(--black-15);
    border-radius: 12px;
    background: var(--black-5);
    padding: 0 20px 0 40px;
    font-weight: 500;
    font-size: 0.9em;
}

.searchbar-item {
    position: relative;
    width: 100%;
}

.searchbar-item:before {
    content: '';
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translate(0, -50%);
    width: 14px;
    height: 14px;
    background: url('../icons/search--gray.svg') no-repeat;
    background-size: contain;
}

.season-chooser-container, .club-logo-container {
    border-left: 1px solid var(--black-5);
    padding: 10px;
}

.user-data-container {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    line-height: 1.2;
}

.user-data-container .user-title {
    color: var(--black-70);
    font-size: .9em;
    font-weight: 700;
}

.user-data-container .user-club {
    font-size: .8em;
    font-weight: 500;
    color: var(--black-50);
}

.season-chooser-container .single-search-input {
    min-width: 210px;
}

.club-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--black-5);
    background: var(--black-5);
    margin: 20px;
    border-radius: 12px;
}

.club-logo-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.club-logo-block img {
    width: 100%;
}

.club-logo-container h2 {
    font-weight: 600;
    font-size: .9em;
    margin-top: 20px;
    text-align: center;
}

.user-item {
    height: 40px;
    background: var(--blue-20);
    border-radius: 12px;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    font-weight: 700;
    padding: 0 50px 0 40px;
    white-space: nowrap;
    position: relative;
    min-width: 200px;
    cursor: pointer;
    transition: all .3s;
    color: var(--blue-170);
}

.user-item:hover {
    background: var(--blue-100);
    color: var(--white-100);
}

.user-item:hover:before {
    background: url('../icons/user-white.svg') no-repeat;
    background-size: contain;
}

.user-item:hover:after {
    background: url('../icons/arrow-toggle-white.svg') no-repeat;
}

.user-item:before {
    content: '';
    position: absolute;
    left: 14px;
    top: 10px;
    width: 15px;
    height: 20px;
    background: url('../icons/user-blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.user-item:after {
    content: '';
    position: absolute;
    right: 12px;
    top: 16px;
    width: 15px;
    height: 22px;
    background: url('../icons/arrow-toggle.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
}

.user-options-item, .delete-button {
    height: 34px;
    border: 1px solid var(--blue-60);
    border-radius: 12px;
    margin-top: 6px;
    background: var(--white-100);
    color: var(--blue-100);
    font-size: 0.9em;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: all .3s;
    padding: 0 10px;
}

.user-options-item:hover {
    background: var(--blue-20);
    color: var(--blue-100);
    text-decoration: none;
}

.user-options-item.users:before {
    width: 18px;
    left: 11px;
    background: url('../icons/users-blue.svg') no-repeat;
    background-size: contain;
}

.user-options-item.logout, .user-options-item.delete {
    border: 1px solid var(--red-100);
    color: var(--red-100);
}

.user-options-item.logout:before {
    background: url('../icons/logout--red.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 11px;
    left: 14px;
    width: 18px;
}

.user-options-item.logout:hover, .user-options-item.delete:hover {
    background: var(--red-100);
    color: var(--white-100);
}

.user-options-item.logout:hover:before {
    background: url('../icons/logout--white.svg') no-repeat;
    background-size: contain;
}

.user-options-item.link:before {
    background: url('../icons/link--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    height: 18px;
    top: 10px;
}

.user-options-item.delete:before {
    background: url('../icons/delete--red.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 11px;
    left: 13px;
    width: 16px;
}

.user-options-item.delete:hover:before {
    background: url('../icons/delete--white.svg') no-repeat;
    background-size: contain;
}

.user-options-item.archive:before {
    background: url('../icons/archive--blue.svg') no-repeat;
    background-size: contain;
    transition: all .3s;
    top: 12px;
    left: 12px;
    width: 19px;
}

.menu-main-items .menu-link, .bottom-menu-container .menu-link {
    display: block;
    margin-bottom: 5px;
}

.menu-main-items .menu-link:last-child {
    margin-bottom: 0;
}

.menu-opening-item, .menu-opening-title {
    margin-bottom: 5px;
}

.menu-opening-item div:first-child {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.menu-opening-item div:first-child span {
    padding-left: 14px;
}

.menu-sub-item, .menu-opening-title, .menu-opening-item {
    min-height: 36px;
    padding: 6px 0;
    position: relative;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all .3s;
}

.menu-opening-item.sorting .sort-buttons {
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 999;
}

.menu-opening-item.sorting .sort-buttons .up, .menu-opening-item.sorting .sort-buttons .down {
    height: 16px;
    width: 24px;
    position: relative;
    border: 1px solid var(--black-5);
    cursor: pointer;
    transition: all .2s;
}

.menu-opening-item.sorting .sort-buttons .up:hover, .menu-opening-item.sorting .sort-buttons .down:hover {
    background: var(--black-5);
    border-color: var(--black-15);
}

.menu-opening-item.sorting .sort-buttons .up {
    border-radius: 6px 6px 0 0;
}

.menu-opening-item.sorting .sort-buttons .down {
    border-radius: 0 0 6px 6px;
}

.menu-opening-item.sorting .sort-buttons .up:before, .menu-opening-item.sorting .sort-buttons .down:before {
    content: '';
    background: url('../icons/caret-up--blue.svg') no-repeat;
    background-size: contain;
    background-position: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    transition: all .3s;
}

.menu-opening-item.sorting {
    cursor: auto;
}

.menu-opening-item.sorting:hover {
    background: unset;
}

.menu-opening-item.sorting .sort-buttons .down:before {
    transform: translate(-50%, -50%) rotate(180deg);
}

.menu-opening-item.sorting .sort-buttons span {
    padding: 0;
}

.menu-opening-item.sorting:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
    background: url('../icons/grip-dots--gray.svg') no-repeat;
    background-size: contain;
    width: 14px;
    height: 14px;
}

.menu-sub-item:after {
    content: '';
    background: url('../icons/arrow-left--blue.svg') no-repeat;
    background-size: contain;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%) rotate(180deg);
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: all .3s;
}

.menu-sub-item:hover:after {
    opacity: 1;
    right: 5px;
}

.menu-opening-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 0;
}

.menu-divider {
    height: 1px;
    background: var(--black-5);
    margin: 0 15px;
}

.menu-sub-item span, .menu-opening-title span, .menu-opening-item span {
    color: var(--black-70);
    padding-left: 40px;
    font-size: .9em;
    transition: all .3s;
    font-weight: 700;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-right: 8px;
    line-height: 1.1;
}

.bottom-menu-container {
    border-top: 1px solid var(--black-5);
    padding: 10px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.menu-opening-title span {
    padding-left: 10px;
    font-size: .8em;
    color: var(--black-50);
}

.menu-opening-item span {
    font-size: .8em;
}

.menu-sub-item-total {
    font-size: 0.7em;
    font-weight: 700;
    min-width: 20px;
    height: 20px;
    color: var(--black-30);
    transition: all .3s;
    opacity: 1;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 0 4px;
}

.menu-sub-item-total.error {
    background: var(--red-100);
    border-radius: 10px;
    color: var(--white-100);
}

.menu-sub-item:hover .menu-sub-item-total {
    opacity: 0;
    padding-right: 5px;
}

.menu-opening-title .add-entity {
    font-weight: 700;
    font-size: 1.3em;
    color: var(--black-50);
    padding: 0;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-right: 4px;
}

.sort-entity {
    width: 22px;
    height: 22px;
    cursor: pointer;
    position: relative;
    transition: all .3s;
    border-radius: 8px;
}

.sort-entity:hover {
    background: var(--black-5);
}

.sort-entity:before {
    content:'';
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url('../icons/sort--light-gray.svg') no-repeat;
    background-size: contain;
}

.sort-entity.reset:before {

}

.menu-opening-title .add-entity:hover {
    background: var(--black-5);
}

.menu-main-items {
    padding: 10px;
}

.menu-sub-item:hover, .menu-sub-item.active, .menu-opening-item.active, .menu-opening-item:hover  {
    background: var(--black-5);
}

.menu-sub-item:hover span, .menu-sub-item.active span, .menu-opening-item.active span, .menu-opening-item:hover span {
    color: var(--blue-120);
}

.menu-link:last-child:before {
    height: 100%;
}

.menu-opening-container {
    padding: 10px 10px 5px 10px;
}

.menu-groups-container {
    padding: 5px 10px 10px 10px;
    height: calc(100vh - 372px);
    overflow-y: scroll;
}

.menu-sub-item:before {
    content:'';
    position: absolute;
    width: 16px;
    height: 16px;
    background: url('../icons/swimmer--blue.svg') no-repeat;
    background-size: contain;
    left: 10px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 9;
    transform-origin: center;
    transition: all .3s;
}

.menu-sub-item.pools:before {
    background: url('../icons/water-ladder--blue.svg') no-repeat;
    background-size: contain;
}

.menu-sub-item.club:before {
    background: url('../icons/club-home--blue.svg') no-repeat;
    background-size: contain;
}

.menu-sub-item.groups:before {
    background: url('../icons/users--blue.svg') no-repeat;
    background-size: contain;
}

.menu-sub-item.seasons:before {
    background: url('../icons/calendar-day--blue.svg') no-repeat;
    background-size: contain;
}

.menu-sub-item.categories:before {
    background: url('../icons/apps--blue.svg') no-repeat;
    background-size: contain;
}

.menu-sub-item.subscriptions:before {
    background: url('../icons/shopping-bag--blue.svg') no-repeat;
    background-size: contain;
}

.menu-sub-item.settings:before {
    background: url('../icons/settings--blue.svg') no-repeat;
    background-size: contain;
}

.menu-sub-item.admins:before {
    background: url('../icons/admins--blue.svg') no-repeat;
    background-size: contain;
}

.menu-sub-item.logout:before {
    background: url('../icons/logout--blue.svg') no-repeat;
    background-size: contain;
}

.menu-sub-item.files:before {
    background: url('../icons/folder--blue.svg') no-repeat;
    background-size: contain;
}

.droppable-groups-sort {
    position: absolute;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
}

.group-block-container {
    position: relative;
}

.searchbar-item .select__control {
    border-radius: 12px;
}

.settings-view .view-header-tabs .MuiTabs-root {
    border-bottom: 1px solid var(--black-5);
}

.settings-view .view-header {
    border-bottom: 0;
}

.settings-view .MuiButtonBase-root.MuiTab-root {
    min-height: 50px;
}

.mobile-top-navbar {
    height: 60px;
    border-bottom: 1px solid var(--black-5);
    display: none;
}

.mobile-top-navbar .app-logo {
    width: 130px;
}

@media (max-width: 981px) {
    .main-navbar {
        display: none;
    }
    .mobile-top-navbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .settings-view .view-header {
        border-bottom: 1px solid var(--black-5);
    }
}