.login-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.login-slider-container {
    height: 100%;
    width: 45%;
}

.main-login {
    height: 100%;
    padding: 20px;
}

.login-right-form {
    height: 100%;
    width: 400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px;
}

.login-right-form h1 {
    font-size: 1.6em;
    font-weight: 600;
    margin-bottom: 20px;
}

.login-right-form h2 {
    font-size: .9em;
    color: var(--black-60);
    font-weight: 600;
    margin-bottom: 40px;
}

.login-right-form input {
    height: 50px;
    background: var(--black-5);
    border: 0;
}

.login-right-form .forgot-password {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 10px 0 20px 0;
}

.login-right-form .main-button {
    width: 100%;
    height: 50px;
    padding: 0;
}

.login-right-form .main-button:before {
    display: none;
}

.login-divider {
    height: 2px;
    background: var(--black-5);
    margin: 30px 0;
    width: 100%;
}

.login-sign-up {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: 600;
    font-size: .9em;
}

.login-background-container {
    border-radius: 20px;
    height: 100%;
    width: 100%;
    background: url('../images/login-background.png') no-repeat;
    background-size: cover;
    box-shadow: 0 0 4px var(--black-50);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
    padding: 50px;
}

.login-background-container h1 {
    color: var(--white-100);
    font-weight: 600;
    font-size: 1.6em;
}

.forgot-password .main-button.blue-button, .form-container .login-message {
    margin-bottom: 20px;
}

.login-message, .error-message {
    width: 100%;
    background: var(--blue-5);
    min-height: 34px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-size: .9em;
    font-weight: 600;
    color: var(--blue-170);
    transition: all .3s;
    opacity: 1;
}

.error-message span {
    font-weight: 600;
}

.login-message.hidden, .error-message.hidden {
    display: block;
    opacity: 0;
    height: 0;
    margin-bottom: 0;
    min-height: 0;
}

.login-message.error, .error-message {
    background: var(--red-30);
    color: var(--red-170);
}

.login-right-form .form-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.login-right-form .rights-container {
    font-size: .8em;
    color: var(--black-50);
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-right-form.sign-up .main-button.blue-button {
    margin-top: 20px;
}

@media (max-width: 981px) {
    .login-right-form {
        padding: 0;
    }
}