.public-router-content a {
    color: var(--blue-120);
}

.public-header {
    height: 80px;
    border-bottom: 1px solid var(--black-5);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.public-container {
    height: 100%;
    padding: 20px;
}

.logo-container {
    padding: 10px 20px;
    width: 170px;
    height: 80px;
}

.logo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.public-router-content {
    height: calc(100dvh - 115px);
    max-width: 1920px;
    margin: auto;
}

.club-block-container {
    border: 1px solid var(--black-5);
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 12px;
}

.club-list-container .MuiPaginationItem-root {
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
}

.club-list-searchbar {
    margin: 30px 0;
}

#map-container {
    height: calc(100dvh - 100px);
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid var(--black-5);
}

.club-filters-container {
    width: 400px;
    height: 100%;
    padding-right: 20px;
}

.clubs-map-container {
    padding: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leaflet-control-zoom.leaflet-control {
    border-radius: 12px;
    overflow: hidden;
}

.map-searchbar {
    position: absolute;
    z-index: 99999;
    top: 40px;
    right: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.map-searchbar .single-search-input {
    max-width: 500px;
    border: 0;
    box-shadow: 0 0 6px var(--black-30);
}

.map-searchbar .single-search-input .select__control {
    height: 50px;
    font-weight: 600;
    font-size: .9em;
    border: 0;
}

.map-searchbar .select__control .custom-tag {
    height: 38px;
}

.clubs-map-container .selected-club-container {
    position: absolute;
    background: var(--white-100);
    top: 110px;
    right: 40px;
    border-radius: 12px;
    z-index: 999;
    max-height: calc(100% - 120px);
    box-shadow: 0 0 6px var(--black-30);
    transition: all .3s;
    width: 0;
    opacity: 0;
    padding: 20px;
}

.clubs-map-container .selected-club-container.selected {
    width: 500px;
    opacity: 1;
}

.selected-club-container h1 {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 0;
    max-width: calc(100% - 30px);
}

.club-view-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
}

.club-info-container {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-right: 1px solid var(--black-5);
}

.club-view-categories {
    width: calc(100% - 300px);
    height: 100%;
    overflow-y: scroll;
    padding: 20px;
}

.club-view-group {
    width: calc(100% - 300px);
    height: 100%;
}

.club-group-form {
    height: calc(100% - 70px);
    overflow-y: scroll;
    padding: 20px;
}

.club-group-container {
    height: 100%;
}

.custom-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    top: 0;
}

.custom-file-label {
    padding: 10px;
    width: 100%;
    min-height: 80px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: var(--black-5);
    border: 1px dashed var(--black-15);
    transition: all .3s;
}

.custom-file-label.template.delete:hover {
    background: var(--red-30);
    border: 1px solid var(--red-100);
}

.custom-file-label.template.delete span {
    color: var(--red-170);
}

.custom-file-label.template, .profile-create-block.filled {
    border: 1px solid var(--black-15);
}

.custom-file-label.enabled {
    border: 1px solid var(--black-15);
}

.custom-file-label.disabled {
    opacity: .4;
    cursor: default;
}

.custom-file-label.disabled:hover {
    background: var(--black-5);
    border: 1px dashed var(--black-15);
}

.custom-file-label.selected {
    background: var(--green-30);
    border: 1px solid var(--green-100);
}

.custom-file-label.selected span {
    color: var(--green-170);
}

.club-info-details *:not(h1, span, a) {
    padding-left: 20px;
    position: relative;
}

.club-info-details *:not(h1, span, a):before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 14px;
    height: 14px;
    background: url('../icons/swimmer--blue.svg') no-repeat;
    background-size: contain;
}

.club-info-details .address:before {
    background: url('../icons/marker-rounded--blue.svg') no-repeat;
    background-size: contain;
}

.club-info-details .email:before {
    background: url('../icons/email-blue.svg') no-repeat;
    background-size: contain;
}

.club-info-details .phone:before {
    background: url('../icons/phone--blue.svg') no-repeat;
    background-size: contain;
}

.club-info-details .website:before {
    background: url('../icons/globe--blue.svg') no-repeat;
    background-size: contain;
}

.public-top-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    width: 100%;
    height: 35px;
    background: var(--black-5);
}

.public-top-header .club-info-details {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.public-top-header .club-info-details * {
    margin-bottom: 0;
    font-size: .9em;
    color: var(--black-70);
    line-height: 1;
}

.club-view-info {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--black-5);
}

.club-view-info h1 {
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 14px;
}

.club-info-details h2, .club-info-details h3, .club-info-details h4, .club-info-details h5 {
    font-size: .9em;
    font-weight: 500;
}

.club-view-info h4 {
    margin-bottom: 0;
}

.club-view-info h5 span {
    font-size: 1em;
}

.club-view-info .logo-container {
    height: 80px;
    border-radius: 10px;
    padding: 0 20px 20px 20px;
    margin: 0 0 20px 0;
    border-bottom: 1px solid var(--black-5);
}

.club-view-info .logo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.club-view-filters {
    width: 100%;
}

.public-header .user-info-container {
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.group-container {
    border: 1px solid var(--black-5);
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 20px;
}

.club-view-filters .title {
    font-size: .9em;
    font-weight: 600;
    margin-bottom: 15px;
    display: block;
}

.category-button {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .9em;
    font-weight: 500;
    margin-bottom: 10px;
}

.category-button .checkbox-container {
    margin: 0 6px 0 0;
}

.group-container h6 {
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 15px;
}

.training-info {
    font-size: .9em;
}

.public-router-content .MuiTableContainer-root {
    border: 1px solid var(--black-5);
    border-radius: 8px;
}

.public-router-content .MuiTableHead-root .MuiTableCell-root {
    font-weight: 600;
    color: var(--blue-120);
}

.public-router-content .MuiTableCell-root {
    border-bottom: 1px solid var(--black-5);
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
}

.public-router-content .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root {
    border-bottom: 0;
}

.club-group-container h1 {
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 0;
}

.club-group-titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid var(--black-5);
}

.MuiInputBase-root.MuiOutlinedInput-root {
    border-radius: 12px;
}

.MuiFormLabel-root.MuiInputLabel-root, .MuiInputBase-root .MuiInputBase-input {
    font-family: 'Manrope', sans-serif;
    font-size: .9em;
}

.club-group-form .MuiInputBase-root .MuiInputBase-input {
    color: var(--blue-120);
    font-weight: 500;
}

.MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--blue-100);
}

.club-coaches-container {
    margin-bottom: 10px;
}

.club-coaches-container .custom-tag {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
}

.training-selector-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    margin-bottom: 15px;
    transition: all .3s;
}

.training-selector-block.disabled {
    opacity: .5;
}

.training-selector-block:last-child, .payment-selector-container, .form-info-container, .licence-form-container {
    margin-bottom: 20px;
}

.form-info-container.field {
    height: 100%;
}

.MuiButtonBase-root.MuiRadio-root.Mui-checked {
    color: var(--blue-100);
}

.navbar-container a {
    margin: 0 15px;
    color: var(--blue-120);
    font-weight: 600;
    transition: all .3s;
    position: relative;
}

.navbar-container a:hover, .navbar-container a.selected {
    color: var(--blue-170);
}

.navbar-container a:before {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 2px;
    border-radius: 1px;
    background: var(--blue-170);
    transition: all .3s;
}

.navbar-container a:hover:before, .navbar-container a.selected:before {
    left: 0;
    width: 100%;
}

.public-header .main-button.login {
    margin-right: 20px;
}

.customer-panel-container {
    height: 100%;
    display: flex;
}

.customer-menu-block {
    width: 100%;
    background: var(--blue-5);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    margin-bottom: 10px;
    transition: all .3s;
    color: var(--blue-170);
}

.mandatory-trainings-container {
    margin-bottom: 20px;
}

.customer-menu-block:hover, .customer-menu-block.active {
    background: var(--blue-120);
    color: var(--white-100);
}

.customer-profile-container {
    border-radius: 12px;
    border: 1px solid var(--black-5);
    padding: 20px;
}

.selected-club-titles {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
}

.profile-create-chooser {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
}

.view-template-button {
    height: 30px;
    border-radius: 8px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px 0 32px;
    top: 10px;
    right: 10px;
    background: var(--white-100);
    border: 1px solid var(--black-15);
    cursor: pointer;
    transition: all .3s;
    font-weight: 600;
    font-size: .8em;
    color: var(--blue-100);
}

.view-template-button:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    width: 14px;
    height: 14px;
    transform: translate(0, -50%);
    background: url('../icons/eye--blue.svg') no-repeat;
    background-size: contain;
}

.view-template-button:hover:after {
    background: url('../icons/eye--white.svg') no-repeat;
    background-size: contain;
}

.profile-edit-button {
    width: 30px;
    height: 30px;
    border-radius: 8px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--white-100);
    border: 1px solid var(--black-15);
    cursor: pointer;
    transition: all .3s;
}

.profile-edit-button:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    transform: translate(-50%, -50%);
    background: url('../icons/pencil--blue.svg') no-repeat;
    background-size: contain;
}

.profile-edit-button:hover, .view-template-button:hover {
    background: var(--blue-100);
    border: 1px solid var(--blue-100);
    color: var(--white-100);
}

.profile-edit-button:hover:after {
    background: url('../icons/pencil--white.svg') no-repeat;
    background-size: contain;
}

.profile-create-block {
    width: 100%;
    height: 80px;
    gap: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: var(--black-5);
    border: 1px dashed var(--black-15);
    cursor: pointer;
    transition: all .3s;
    text-align: center;
}

.profile-create-chooser.active .profile-create-block {
    border: 1px solid var(--green-120);
    background: var(--green-30);
}

.profile-create-chooser.active .profile-create-block span {
    color: var(--green-170);
}

.profile-create-chooser span, .custom-file-label span {
    font-size: .9em;
    font-weight: 600;
    color: var(--blue-120);
    text-align: center;
    line-height: 1.2;
}

.profile-create-block:hover, .profile-create-block.active, .custom-file-label:hover {
    background: var(--blue-20);
    border: 1px solid var(--blue-100);
}

.MuiPickersPopper-root.MuiPopper-root {
    z-index: 99999999;
}

.submit-subcription.main-button, .group-container .main-button, .planning-group-content .main-button {
    width: 100%;
    height: 34px;
    padding: 0;
    border-radius: 8px;
    margin-top: 20px;
}

.submit-subcription.main-button:before, .group-container .main-button:before, .planning-group-content .main-button:before {
    display: none;
}

.club-group-form .error-message, .licence-sign-container .error-message {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.custom-file-container, .custom-file-container .rich-text-container {
    position: relative;
    margin-bottom: 20px;
}

.form-info-container .rich-text-container {
    margin-bottom: 10px;
}

.group-container .border-left, .planning-public-details .border-left {
    border-left: 1px solid var(--black-5);
    border-bottom: 0;
}

.group-details .rich-text-container {
    margin-bottom: 20px;
}

.customer-panel-content {
    height: calc(100dvh - 115px);
    overflow-y: scroll;
}

.customer-profiles, .customer-info-container, .customer-subscriptions-container {
    padding: 15px;
}

.success-subscription {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 12px;
    background: var(--green-30);
    padding: 50px;
    gap: 20px;
    margin-bottom: 20px;
}

.success-subscription h1 {
    font-size: 1.2em;
    font-weight: 700;
}

.payment-selector-block .MuiFormControlLabel-root {
    margin-right: 0;
}

.payment-selector-block {
    display: flex;
    align-items: center;
    gap: 8px;
}

.mobile-burger-menu {
    display: none;
    margin-right: 20px;
    cursor: pointer;
}

.mobile-burger-menu-content {
    background: var(--white-100);
    height: 0;
    width: 100%;
    transition: all .3s;
    padding: 20px;
    opacity: 0;
    position: absolute;
    top: 80px;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: -9999999;
    overflow: hidden;
}

.mobile-burger-menu-content.open {
    height: calc(100dvh - 80px);
    opacity: 1;
    z-index: 9999999;
}

.mobile-burger-menu-content a {
    padding: 10px 15px;
    border-radius: 10px;
    text-align: center;
    color: var(--blue-120);
    background: var(--blue-5);
    border: 1px solid var(--blue-20)
}

.mobile-burger-menu-content a.logout {
    border-color: var(--red-30);
    color: var(--red-170);
    background: var(--red-30);
}

.main-login.forgot-password #email {
    margin-bottom: 20px;
}

.customer-message {
    background: var(--blue-5);
    padding: 10px;
    border-radius: 8px;
    font-size: .9em;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.customer-message.waiting-validation {
    background: var(--yellow-30);
    color: var(--yellow-170);
}

.customer-message.waiting-payment {
    background: var(--purple-30);
    color: var(--purple-120);
}

.customer-message.refused {
    background: var(--red-30);
    color: var(--red-170);
}

.customer-message strong {
    font-weight: 600 !important;
}

.change-subscription-container .form-info-container:last-of-type {
    margin-bottom: 0;
}

.general-condition-info {
    line-height: 1;
    margin-bottom: 15px;
}

.general-condition-info span {
    font-size: 0.8em;
    font-weight: 600;
    color: var(--black-60);
}

.file-max-info {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.file-max-info small {
    font-size: 0.7em;
    font-weight: 600;
    color: var(--black-60);
}

.subscription-validation .public-subscription-block, .change-subscription-container .public-subscription-block {
    border: 0;
    padding: 0;
}

.public-subscription-block {
    border: 1px solid var(--black-5);
    border-radius: 12px;
    padding: 15px;
}

.public-subscription-list {
    margin-bottom: 15px;
}

.public-subscription-block .submit-subcription.main-button {
    margin-top: 0;
}

.training-selector-block.disabled .checkbox-container {
    cursor: default;
}

.subscription-line {
    padding-left: 26px;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    margin-bottom: 10px;
    min-height: 30px;
}

.subscription-line-title {
    font-size: .9em;
    font-weight: 700;
    color: var(--black-100);
}

.subscription-line:before {
    content: '';
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background: url('../icons/info--blue.svg') no-repeat;
    background-size: contain;
}

.subtitle-required {
    color: var(--red-100);
}

.subscription-closed-info img {
    margin-bottom: 20px;
}

.subscription-closed-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10vh 20px;
    border: 1px solid var(--black-5);
    border-radius: 12px;
    text-align: center;
}

.subscription-closed-info h3 {
    font-size: 1em;
    font-weight: 600;
}

.public-planning-container {
    height: calc(100dvh - 115px);
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.planning-public-details {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 50%;
    background: var(--white-100);
    height: 50vh;
    z-index: 9;
    border-radius: 12px;
    border: 1px solid var(--black-5);
    padding: 20px;
    transition: all .2s;
}

.planning-public-details.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.planning-public-details.closed {
    right: calc(-50% - 30px);
}

.planning-group-content {
    margin-top: 15px;
    height: calc(50vh - 100px);
    overflow-y: scroll;
}

.licence-form-data {
    border: 1px solid var(--black-5);
    padding: 15px;
    border-radius: 10px;
}

.MuiFormControlLabel-label strong {
    font-weight: 700;
}

.MuiFormControl-root .MuiRadio-root {
    padding: 4px 9px;
}

.licence-form-data .view-subtitle {
    margin-bottom: 10px;
}

.licence-sign-container {
    background: var(--black-5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
}

.licence-sign-container.open {
    z-index: 99;
    display: flex;
    align-items: center;
}

.licence-sign-container.open.verification {
    align-items: flex-start;
    overflow: hidden;
}

.licence-sign-pdf-container {
    width: calc(100% - 450px);
    height: 100dvh;
    overflow-y: scroll;
    background: var(--black-5);
}

.licence-sign-container.verification .licence-sign-pdf-container {
    width: 100%;
    height: calc(100dvh - 120px);
}

.licence-sign-form-container {
    background: var(--white-100);
    height: 100dvh;
    width: 450px;
    border-left: 1px solid var(--black-10);
    position: relative;
}

.licence-sign-form-header {
    padding: 15px;
    border-bottom: 1px solid var(--black-10);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
}

.licence-sign-form-body {
    padding: 15px;
    height: calc(100dvh - 120px);
    overflow-y: scroll;
}

.licence-sign-pdf-container .react-pdf__Page {
    display: flex;
    justify-content: center;
    background: var(--black-5) !important;
    padding: 20px;
}

.licence-sign-pdf-container .react-pdf__Page canvas {
    box-shadow: 0 0 10px var(--black-10);
}

.licence-sign-form-body .disabled-section {
    background: var(--black-5);
    border-radius: 8px;
    padding: 10px;
    color: var(--black-50);
}

.erase-signature {
    position: absolute;
    top: 8px;
    right: 8px;
}

.erase-signature button {
    border-radius: 7px;
}

.signature-container {
    position: relative;
}

.signature-container.error .sigCanvas {
    border: 1px solid var(--red-120);
}

.error-signature {
    position: absolute;
    left: 6px;
    top: 7px;
}

.sigCanvas {
    border: 1px solid var(--black-10);
    border-radius: 12px;
    width: 100%;
    height: 150px;
}

.licence-sign-note {
    background: var(--blue-5);
    color: var(--blue-100);
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 10px;
    border-radius: 12px;
    font-size: .9em;
}

.licence-sign-note.warning {
    background: var(--yellow-30);
    color: var(--yellow-170);
    font-style: italic;
}

.licence-sign-form-buttons {
    background: var(--white-100);
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 15px;
    gap: 10px;
    border-top: 1px solid var(--black-10);
}

.licence-signature-chooser .custom-file-container {
    margin-bottom: 0;
}

.licence-signature-chooser-option-title {
    font-size: .9em;
    font-weight: 600;
    text-align: center;
    width: 100%;
    display: block;
}

.pdf-loader-container {
    height: 100dvh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.MuiTableRow-root.blue-row {
    background: var(--blue-120);
}

.MuiTableRow-root.blue-row .MuiTableCell-root {
    color: var(--white-100);
    text-align: center;
}

.licence-sign-verification-container {
    position: absolute;
    background: var(--white-100);
    bottom: 0;
    width: 100%;
    border-top: 1px solid var(--black-10);
    display: none;
    padding: 15px;
    height: 120px;
}

.licence-sign-verification-container.open {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.licence-sign-verification-container .licence-sign-note {
    width: 100%;
    text-align: center;
}

.licence-sign-verification-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}

.licence-sign-container.verification .licence-sign-form-container {
    position: absolute;
    opacity: 0;
    z-index: -999;
}

.training-selector-choice {
    display: flex;
    border: 1px solid var(--black-5);
    border-radius: 12px;
    padding: 10px;
    transition: all .3s;
    align-items: center;
    justify-content: space-between;
}

.training-selector-choice.disabled {
    opacity: .5;
}

.training-selector-choice .training-selector-line {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.training-selector-choice .training-selector-line:last-child {
    margin-bottom: 0;
}

.payment-selector-block .MuiRadio-root {
    padding: 0 9px;
}

@media (max-width: 981px) {
    .data-doughnut-capacity h2 {
        font-size: 1.2em;
    }
    .data-doughnut-capacity span {
        font-size: .7em;
    }
    .public-top-header, .club-info-container, .public-header .user-info-container, .navbar-container, .public-header .user-info-container {
        display: none;
    }
    .public-router-content {
        height: calc(100dvh - 80px);
    }
    .public-header .button-login {
        display: none;
    }
    .logo-container {
        width: 150px;
    }
    .mobile-burger-menu {
        display: flex;
    }
    .club-view-categories {
        width: 100%;
    }
    .club-view-group {
        width: 100%;
    }
    .group-container {
        border: 0;
        padding: 0 0 20px;
        border-bottom: 1px solid var(--black-5);
    }
    .group-container:last-child {
        border-bottom: 0;
        margin-bottom: 80px;
    }
    .MuiTableContainer-root .MuiTable-root .hide-mobile {
        display: none;
    }
    .login-slider-container {
        display: none;
    }
    .customer-menu {
        display: none;
    }
    .main-app-right-part, .customer-panel-content {
        width: 100%;
    }
    .ios-margin {
        height: 80px;
        width: 100%;
    }
    .customer-panel-content {
        height: calc(100dvh - 80px);
    }
    .view-template-button {
        position: relative;
        top: 0;
        left: 0;
        margin: 15px 0;
        padding: unset;
    }
    .view-template-button:after {
        display: none;
    }
    .accordion-training-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--black-5);
        padding: 10px;
        border-radius: 8px;
    }
    .public-planning-container {
        height: calc(100dvh - 80px);
    }
    .planning-public-details {
        bottom: 20px;
        right: 20px;
        width: calc(100% - 40px);
        border-radius: 8px;
        height: calc(100dvh - 175px);
    }
    .planning-public-details.closed {
        right: calc(-100% - 30px);
    }
    .planning-group-content {
        height: calc(100dvh - 275px);
    }
    .licence-sign-container.open .licence-sign-pdf-container {
        display: none;
    }
    .licence-sign-container.open.verification .licence-sign-pdf-container {
        display: block;
    }
    .licence-sign-form-container {
        width: 100%;
        border-left: 0;
    }
    .licence-sign-form-buttons {
        position: relative;
        padding: 0;
        border-top: 0;
    }
    .licence-sign-form-body {
        height: calc(100dvh - 60px);
    }
}

@media (max-width: 768px) {
    .public-panel-container .fc .fc-toolbar.fc-header-toolbar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .public-panel-container .fc-toolbar-chunk:first-child {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
    .planning-public-details {
        height: calc(100dvh - 208px);
    }
    .planning-group-content {
        height: calc(100dvh - 308px);
    }
}